<!--
 * @Author: qinmengyuan
 * @Date: 2024-07-23 14:30:53
 * @LastEditors: qinmengyuan 2715025514@qq.com
 * @LastEditTime: 2024-08-20 09:49:12
 * @FilePath: \dataview-viewer-test\src\views\todo\components\rightCont\shortCutMenu\ShortcutMenuPop.vue
-->
<template>
  <Dialog
    class="menu-modal"
    :visible.sync="dialogVisible"
    width="640px"
    :before-close="handleClose"
    :append-to-body="true"
  >
    <div class="content">
      <div class="modal-left">
        <div class="title">设置</div>
        <Input v-model="keyword" placeholder="搜索菜单名称" />
        <div class="category">
          <CheckboxGroup v-model="list" :max="12" v-if="num">
            <div class="sort-item" v-for="sort in sortList" :key="sort.id">
              <div class="sort-name">
                {{ sort.group_name }}
              </div>
              <div
                class="item"
                v-for="item in sort.personal_shortcut_org_list.filter((per) =>
                  per.shortcut_name?.includes(keyword)
                )"
                :key="item.id"
              >
                <Checkbox :label="item.id">
                  <div :class="{ icon: true }">
                    <!-- <svg
                      v-if="!item.shortcut_icon?.includes('el-icon')"
                      aria-hidden="true"
                      class="iconfont-svg"
                      :style="{ fill: '#fff', width: 10, height: 10 }"
                    >
                      <use :xlink:href="'#' + item.shortcut_icon" />
                    </svg> -->
                    <i
                      :class="{ iconfont: true, [item.shortcut_icon]: true }"
                    ></i>
                  </div>
                  <div class="name">{{ item.shortcut_name }}</div></Checkbox
                >
              </div>
            </div>
          </CheckboxGroup>
          <div class="empty" v-else>暂无数据</div>
        </div>
      </div>
      <div class="modal-right">
        <div class="title">
          快捷菜单 <span class="num">{{ `（ ${list.length} / 12）` }}</span>
        </div>
        <div class="describe">
          <span>长按</span>
          <i class="iconfont iconchangantuodong"></i>
          <span>拖动调整顺序</span>
        </div>
        <transition-group name="drag" class="list" tag="ul">
          <li
            @dragenter="dragenter($event, index)"
            @dragstart="dragstart($event, index)"
            @dragover.prevent
            draggable
            v-for="(item, index) in checkedList"
            :key="item.value"
            class="list-item"
          >
            <div class="item-cont">
              <i class="iconfont iconchangantuodong"></i>
              <div :class="{ icon: true }">
                <!-- <svg
                  v-if="!item.shortcut_icon?.includes('el-icon')"
                  aria-hidden="true"
                  class="iconfont-svg"
                  :style="{ fill: '#fff', width: 10, height: 10 }"
                >
                  <use :xlink:href="'#' + item.shortcut_icon" />
                </svg> -->
                <i :class="{ iconfont: true, [item.shortcut_icon]: true }"></i>
              </div>
              <span>{{ item.label }}</span>
            </div>
            <div class="close" @click="handleDelete(item)">
              <i class="iconfont iconclose"></i>
            </div>
          </li>
        </transition-group>
        <div class="btns">
          <Button type="info" class="btn" @click="handleClose()" plain>
            取消
          </Button>
          <Button
            class="btn"
            type="primary"
            @click="handleOk"
            :loading="saveLoading"
          >
            确定
          </Button>
        </div>
      </div>
    </div>
  </Dialog>
</template>

<script setup>
/* eslint-disable */
import { ref, onMounted, computed, watch } from "vue";
import { Dialog, Input, Checkbox, CheckboxGroup, Button } from "element-ui";
import { dataInterface } from "../../../../../apis/data";
const props = defineProps({
  dialogVisible: {
    type: Boolean,
    default: false,
  },
  menuList: {
    type: Array,
    default: () => [],
  },
  dataId: {
    type: [String, Number],
    default: "",
  },
});
const emits = defineEmits(["close"]);
const keyword = ref("");
const saveLoading = ref(false);
const dragIndex = ref("");
const list = ref([]);
const num = ref(1);
const checkedList = computed(() =>
  list.value.map((item) => ({
    value: item,
    label: getLabel(item, sortList.value).name,
    shortcut_icon: getLabel(item, sortList.value).shortcut_icon,
  }))
);
watch(
  () => props.menuList,
  () => {
    list.value = [...props.menuList];
  }
);
const sortList = ref([]);
const handleClose = (refresh) => {
  emits("close", refresh);
  if (!refresh) {
    list.value = [...props.menuList];
  }
};
/**
 * @name:
 * @description: 拖拽排序
 * @msg:
 * @param {*} e
 * @param {*} index
 * @return {*}
 */
const dragstart = (e, index) => {
  dragIndex.value = index;
};
const dragenter = (e, index) => {
  e.preventDefault();
  if (dragIndex.value !== index) {
    const source = list.value[dragIndex.value];
    list.value.splice(dragIndex.value, 1);
    list.value.splice(index, 0, source);
    dragIndex.value = index;
  }
};
const getLabel = (key, list = []) => {
  let name;
  let shortcut_icon;
  list.forEach((item) => {
    if (item.id === key && item.shortcut_name) {
      name = item.shortcut_name;
      shortcut_icon = item.shortcut_icon;
    } else {
      if (Array.isArray(item.personal_shortcut_org_list)) {
        if (getLabel(key, item.personal_shortcut_org_list)?.name) {
          name = getLabel(key, item.personal_shortcut_org_list).name;
          shortcut_icon = getLabel(
            key,
            item.personal_shortcut_org_list
          ).shortcut_icon;
        }
      }
    }
  });
  return { name, shortcut_icon };
};
/**
 * @name:
 * @description: 获取左侧列表
 * @msg:
 * @return {*}
 */
const getLeftList = () => {
  const permissionsList = localStorage.getItem("userRoles")?.split(",");
  const systemConfig = JSON.parse(localStorage.getItem("systemConfig"));
  const params = {
    object_uuid: "object66a89cc215a22",
    view_uuid: "view66a8bb2a1979b",
    __method_name__: "dataList",
    transcode: 0,
    page_project_id: systemConfig.id,
  };
  dataInterface(params).then((res) => {
    if (res.data.code === 200) {
      sortList.value = res.data.data.filter(
        (item) =>
          item.personal_shortcut_org_list?.filter(
            (p) => !p.permissions || permissionsList?.includes(p.permissions)
          ).length > 0
      );
    }
  });
};
/**
 * @name:
 * @description: 保存/新增
 * @msg:
 * @return {*}
 */
const handleOk = () => {
  saveLoading.value = true;
  const params = {
    object_uuid: "object66a89f9fe28bd",
    view_uuid: "view66a8b1dfe9409",
    __method_name__: props.dataId ? "updateData" : "createData",
    transcode: 0,
    org_list_id: list.value,
    ...(props.dataId && { data_id: props.dataId }),
  };
  dataInterface(params)
    .then((res) => {
      if (res.data.code === 200) {
        handleClose(true);
      }
    })
    .finally(() => {
      saveLoading.value = false;
    });
};
const handleDelete = (item) => {
  list.value = list.value.filter((l) => l !== item.value);
};
onMounted(() => {
  getLeftList();
});
watch(
  () => keyword.value,
  () => {
    let length = 0;
    sortList.value.forEach((item) => {
      length += item.personal_shortcut_org_list.filter((per) =>
        per.shortcut_name?.includes(keyword.value)
      ).length;
    });
    num.value = length;
  }
);
</script>
<style lang="less" scoped>
.menu-modal {
  ::v-deep(.el-dialog) {
    // padding: 0 20px;
    border-radius: 16px;
    height: 560px;
    overflow: hidden;
    .el-dialog__header {
      display: none;
    }
    .el-dialog__body {
      padding: 0;
      width: 100%;
      height: 100%;
    }
  }
  .content {
    width: 100%;
    height: 100%;
    display: flex;
    .title {
      display: flex;
      height: 52px;
      padding: 4px var(--spacing-10, 20px) 0px var(--spacing-10, 20px);
      align-items: center;
      color: var(--text-on-surface-primary, #181b22);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
      .num {
        color: #a4acbd;
      }
    }
    .modal-left {
      flex: 1;
      background: #f2f5fa;
      display: flex;
      flex-direction: column;
      .title {
        flex-shrink: 0;
      }
      ::v-deep(.el-input) {
        padding: 0 16px;
        width: -webkit-fill-available;
        .el-input__inner {
          border-radius: 6px;
          &:hover {
            background: #fff;
            border: 1px solid #dcdfe6;
          }
          &:focus {
            border: 1px solid var(--themeColor);
            background: #fff;
            box-shadow: 0px 0px 0px 0px var(--themeColor) inset,
              0px 0px 0px 2px rgba(var(--themeColorRgb), 0.2);
          }
          &::placeholder {
            color: #a4acbd;
            font-weight: 400;
          }
        }
      }
      .category {
        padding: 8px 20px;
        display: flex;
        flex-direction: column;
        flex: 1;
        overflow-y: auto;
        gap: 12px;
        .sort-item {
          display: flex;
          flex-direction: column;
          gap: 4px;
          .sort-name {
            color: #67708f;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0.4px;
          }
          .item {
            height: 32px;
            display: flex;
            align-items: center;
            gap: 8px;
            ::v-deep(.el-checkbox) {
              display: flex;
              align-items: center;
              .el-checkbox__label {
                display: flex;
                align-items: center;
                gap: 8px;
              }
            }
            .icon {
              display: flex;
              align-items: center;
              width: 18px;
              height: 18px;
              border-radius: var(--Radio-4, 4px);
              background: var(--themeColor);
              justify-content: center;
            }
            .svg-icon {
              background: transparent;
            }
            .iconfont {
              color: white;
              font-size: 10px;
            }
            .name {
              color: #181b22;
              letter-spacing: 0.5px;
              text-transform: capitalize;
            }
          }
        }
        .empty {
          display: flex;
          flex: 1;
          align-items: center;
          justify-content: center;
        }
      }
    }
    .modal-right {
      flex: 1;
      display: flex;
      flex-direction: column;

      .iconchangantuodong {
        display: flex;
        width: 18px;
        height: 18px;
        justify-content: center;
        align-items: center;
        border-radius: var(--radius-2, 4px);
        background: var(--overall-surface-default, #fff);
        font-size: 12px;
        color: #4d533e;
      }
      .describe {
        margin: 0 16px;
        height: 32px;
        display: flex;
        align-items: center;
        border-radius: var(--radius-3, 6px);
        background: var(--overall-surface-variant, #f2f5fa);
        padding: 0 10px;
        gap: 4px;
        span {
          color: var(--semantic-brand-black-secondary, #585f6c);
          font-size: 12px;
          font-style: normal;
          line-height: 18px; /* 150% */
        }
      }
      .list {
        list-style: none;
        padding: 8px 16px;
        flex: 1;

        .drag-move {
          transition: transform 0.3s;
        }
        .list-item {
          display: flex;
          padding: 5px 8px;
          align-items: center;
          justify-content: space-between;
          border-radius: 5px;
          overflow: hidden;
          margin-bottom: 4px;
          .item-cont {
            display: flex;
            gap: 8px;
            align-items: center;

            .icon {
              display: flex;
              align-items: center;
              width: 18px;
              height: 18px;
              border-radius: var(--Radio-4, 4px);
              background: var(--themeColor);
              justify-content: center;

              .iconfont {
                color: white;
                font-size: 10px;
              }
            }
            .svg-icon {
              background: transparent;
            }
            span {
              color: var(--text-on-surface-primary, #181b22);
            }
          }
          .iconclose {
            color: rgba(77, 83, 94, 1);
            font-size: 16px;
            &:hover {
              color: #409eff;
            }
          }
        }
      }
      .btns {
        display: flex;
        align-items: center;
        padding: 16px 20px;
        justify-content: space-between;
        .btn {
          width: 134px;
          border-radius: 6px;
        }
        .is-plain {
          background: #f0f1f4;
          border: none;
          color: #424751;
          &:hover,
          &:focus {
            color: #909399;
            background: #f4f4f5;
            border-color: none;
          }
        }
      }
    }
  }
}
</style>
